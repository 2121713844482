<template>
  <div id="modal-another">
    <el-dialog
      :visible.sync="dataOpen"
      @close="closeModal()"
      :width="'80%'"
      :show-close="false"
      top="8vh"
    >
      <el-row v-if="dataById">
        <el-col :span="24" :md="8" class="modal-l">
          <div class="top-detail-modal">
            <div class="logo-modal">
              <img :src="logo" />
            </div>
            <div class="box-fac">
              <p class="thai-fac">{{ dataById.nameTH }}</p>
              <p class="end-fac">{{ dataById.nameEN }}</p>
              <!-- <p class="name-branch">แพทย์ มช. | Med CMU</p> -->
            </div>
          </div>
          <div class="box-detail-contact">
            <h3 class="title-contact">ข้อมูลการติดต่อ</h3>
            <p class="d-flex color-white">
              <span class="left-icon">
                <img src="../assets/image/icon/map.svg" alt />
              </span>
              <span>{{
                dataById.address +
                " ต." +
                dataById.subDistrict +
                " อ." +
                dataById.district +
                " จ." +
                dataById.province +
                " " +
                dataById.postcode
              }}</span>
            </p>
            <p class="d-flex">
              <span class="left-icon">
                <img src="../assets/image/icon/phone.svg" alt />
              </span>
              <span>{{ dataById.phone }}</span>
            </p>
            <p class="d-flex">
              <span class="left-icon">
                <img src="../assets/image/icon/messengeCard.svg" alt />
              </span>
              <span>
                <a :href="dataById.email" target="_blank">{{
                  dataById.email
                }}</a>
              </span>
            </p>
            <p class="d-flex">
              <span class="left-icon">
                <img src="../assets/image/icon/website.svg" alt />
              </span>
              <span>
                <a :href="dataById.website" target="_blank">{{
                  dataById.website
                }}</a>
              </span>
            </p>
          </div>
        </el-col>
        <el-col :span="24" :md="16" class="modal-r">
          <div class="group-text">
            <div class="box-text-detail">
              <p class="d-flex">
                <span class="left-icon">
                  <img src="../assets/image/icon/info.svg" alt />
                </span>
                <span class="text-title">ข้อมูลของหน่วยงาน</span>
              </p>
              <el-row :gutter="30">
                <el-col
                  :span="24"
                  :sm="12"
                  :md="8"
                  class="grid-content inside-group"
                >
                  <p class="head-list">
                    เลขที่ประจำตัวนิติบุคคล ของหน่วยงาน/รหัสหน่วยงาน
                  </p>
                  <p
                    v-if="typeof dataById.information != 'undefined'"
                    class="under-list"
                  >
                    {{ dataById.information.agencyCode }}
                  </p>
                </el-col>
                <el-col
                  :span="24"
                  :sm="12"
                  :md="8"
                  class="grid-content inside-group"
                >
                  <p class="head-list">ประเภทการให้บริการ</p>
                  <p
                    v-if="typeof dataById.information != 'undefined'"
                    class="under-list"
                  >
                    {{ dataById.information.typeOfService }}
                  </p>
                </el-col>
                <el-col
                  :span="24"
                  :sm="12"
                  :md="8"
                  class="grid-content inside-group"
                >
                  <p class="head-list">การสนับสนุนธุรกิจเป้าหมาย</p>
                  <p
                    v-if="typeof dataById.information != 'undefined'"
                    class="under-list"
                  >
                    {{ dataById.information.targetBusiness }}
                  </p>
                </el-col>
              </el-row>
            </div>
            <div class="box-text-detail">
              <p class="d-flex">
                <span class="left-icon">
                  <img src="../assets/image/icon/layers.svg" alt />
                </span>
                <span class="text-title">พื้นที่การให้บริการ</span>
              </p>
              <div class="div-custom">
                <span class="text-show-right">
                  ขนาดพื้นที่ใช้สอยทั้งหมด
                  <span v-if="dataById.serviceArea" class="font-regular">{{
                    dataById.serviceArea.area | commaNoDot
                  }}</span>
                  ตร.ม.
                </span>
              </div>
              <div class="inside-group">
                <p v-if="dataById.serviceArea">
                  {{ dataById.serviceArea.text }}
                </p>
              </div>
            </div>
            <div class="box-text-detail">
              <p class="d-flex">
                <span class="left-icon">
                  <img src="../assets/image/icon/doctor.svg" alt />
                </span>
                <span class="text-title">ข้อมูลของบุคลากร</span>
              </p>
              <div class="inside-group">
                <el-collapse
                  class="custom-collapse"
                  v-model="activeNames"
                  accordion
                >
                  <el-collapse-item name="1">
                    <template ate slot="title">
                      บุคลากรเฉพาะทาง
                      <span class="count-list">{{
                        sumEquipment(dataById.specialized) | commaNoDot
                      }}</span>
                    </template>
                    <div
                      class="tab-menu"
                      v-for="(dataEquipment, index) in dataById.specialized"
                      :key="index"
                    >
                      <p v-if="dataEquipment != 0">
                        <span v-if="index == 'dentist'">ทันตแพทย์</span>
                        <span v-if="index == 'etc'">อื่นๆ</span>
                        <span v-if="index == 'medicalRadiologist'"
                          >การแพทย์</span
                        >
                        <span v-if="index == 'medicalTechnician'"
                          >นักเทคนิคการแพทย์</span
                        >
                        <span v-if="index == 'nurse'">พยาบาล</span>
                        <span v-if="index == 'nursingAssistant'"
                          >ผู้ช่วยพยาบาล</span
                        >
                        <span v-if="index == 'pharmacy'">เภสัชกร</span>
                        <span v-if="index == 'physicalTherapist'"
                          >กายภาพบำบัด</span
                        >
                        <span v-if="index == 'physician'">แพทย์</span>
                        <span v-if="index == 'professor'">ศาสตราจารย์</span>
                        <span v-if="index == 'veterinary'">สัตวแพทย์</span>

                        <span>{{ dataEquipment | commaNoDot}}</span>
                      </p>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item>
                    <template slot="title">
                      บุคลากรสนับสนุน
                      <span class="count-list">{{
                        sumEquipment(dataById.support) | commaNoDot
                      }}</span>
                    </template>
                    <div
                      class="tab-menu"
                      v-for="(dataSupport, index) in dataById.support"
                      :key="index"
                    >
                      <p v-if="dataSupport != 0">
                        <span v-if="index == 'backOffice'">งานทั่วไป</span>
                        <span v-if="index == 'dentist'">ทันตแพทย์</span>
                        <span v-if="index == 'etc'">อื่นๆ</span>
                        <span v-if="index == 'medicalRadiologist'"
                          >การแพทย์</span
                        >
                        <span v-if="index == 'medicalTechnician'"
                          >นักเทคนิคการแพทย์</span
                        >
                        <span v-if="index == 'nurse'">พยาบาล</span>
                        <span v-if="index == 'nursingAssistant'"
                          >ผู้ช่วยพยาบาล</span
                        >
                        <span v-if="index == 'pharmacy'">เภสัชกร</span>
                        <span v-if="index == 'physicalTherapist'"
                          >กายภาพบำบัด</span
                        >
                        <span v-if="index == 'physician'">แพทย์</span>
                        <span v-if="index == 'professor'">ศาสตราจารย์</span>
                        <span v-if="index == 'researchAssistant'"
                          >ผู้ช่วยวิจัย</span
                        >
                        <span v-if="index == 'researcher'">นักวิจัย</span>
                        <span v-if="index == 'scientist'">นักวิทยาศาสตร์</span>
                        <span v-if="index == 'veterinary'">สัตวแพทย์</span>

                        <span>{{ dataSupport | commaNoDot}}</span>
                      </p>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item>
                    <template slot="title">
                      นักศึกษา
                      <span class="count-list">{{
                        sumEquipment(dataById.student)| commaNoDot
                      }}</span>
                    </template>
                    <div
                      class="tab-menu"
                      v-for="(dataStudent, index) in dataById.student"
                      :key="index"
                    >
                      <p v-if="dataStudent != 0">
                        <span v-if="index == 'bachelor'">ปริญญาตรี</span>
                        <span v-if="index == 'graduate'">จบการศึกษา</span>
                        <span v-if="index == 'master'">มหาบัณฑิต</span>
                        <span v-if="index == 'phD'">ปริญญาเอก</span>

                        <span>{{ dataStudent | commaNoDot}}</span>
                      </p>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
            <div class="box-text-detail">
              <p class="d-flex">
                <span class="left-icon">
                  <img src="../assets/image/icon/hospital.svg" alt />
                </span>
                <span class="text-title">ข้อมูลของอุปกรณ์ทางการแพทย์</span>
              </p>
              <el-row :gutter="30">
                <el-col
                  :span="24"
                  :sm="12"
                  :md="12"
                  class="grid-content inside-group"
                >
                  <el-row :gutter="5">
                    <el-col :span="12">
                      <p>เตียงผู้ป่วย</p>
                    </el-col>
                    <el-col :span="6" class="text-right">
                      <p>{{ dataById.medicalEquipment.patienBed | commaNoDot}}</p>
                    </el-col>
                    <el-col :span="6" class="text-right">
                      <p>เตียง</p>
                    </el-col>
                    <el-col :span="12">
                      <p>หอผู้ป่วย</p>
                    </el-col>
                    <el-col :span="6" class="text-right">
                      <p>{{ dataById.medicalEquipment.ward | commaNoDot}}</p>
                    </el-col>
                    <el-col :span="6" class="text-right">
                      <p>หอ</p>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col
                  :span="24"
                  :sm="12"
                  :md="12"
                  class="grid-content inside-group"
                >
                  <p class="head-list">อุปกรณ์ทางการแพทย์</p>
                  <p
                    class="under-list"
                    v-for="(dataEquipment, index) in dataById.medicalEquipment
                      .equipment"
                    :key="index"
                  >
                    - {{ dataEquipment.text }}
                  </p>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="box-to-site text-center">
            <button class="btn btn-line-out">
              <router-link
                :to="{
                  name: 'serviceOfFaculty',
                  query: { serviceId: dataById._id },
                }"
                >บริการทั้งหมด</router-link
              >
            </button>
            <button class="btn btn-line-out">
              <router-link
                :to="{ name: 'innovation', query: { serviceId: dataById._id } }"
                >ข้อมูลด้านนวัตกรรม</router-link
              >
            </button>
            <button class="btn btn-line-out">
              <router-link
                :to="{
                  name: 'intellectualProperty',
                  query: { serviceId: dataById._id },
                }"
                >ข้อมูลทรัพย์สินทางปัญญา</router-link
              >
            </button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios.js";
export default {
  props: ["dataOpen", "serviceId"],
  computed: {
    logo() {
      if (this.dataById.logo) {
        return `${this.urlService}${this.dataById._id}/${this.dataById.logo}`;
      } else {
        return "https://staging-mdc.robinson.co.th/media/aw_rbslider/slides/default-image_7.png";
      }
    },
  },
  data() {
    return {
      urlService: process.env.VUE_APP_BASE_URL_SERVICE,
      activeNames: ["1"],
      dataById: null,
    };
  },
  watch: {
    serviceId() {
      if (this.serviceId) {
        this.dataServiceById();
      }
    },
  },
  mounted() {
    if (this.serviceId) {
      this.dataServiceById();
    }
  },
  methods: {
    closeModal() {
      this.$emit("dataClose", false);
      console.log(this.dataById);
    },
    sumEquipment(data) {
      var totalEquipment = 0;
      Object.keys(data).map((x) => {
        totalEquipment += data[x];
      });
      return totalEquipment;
    },
    dataServiceById() {
      HTTP.get(`service/` + this.serviceId)
        .then((res) => {
          if (res.data.success) {
            this.dataById = res.data.obj;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("dataServiceById error", e);
        });
    },
  },
};
</script>
