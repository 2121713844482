<template>
  <div class="box-card-long" :id="address._id">
    <el-card class="box-card" :class="addressId == address._id ? 'active' : ''">
      <div slot="header" class="box-top-inside d-flex">
        <span class="show-logo">
          <div class="logo-card">
            <img :src="logo" />
          </div>
        </span>
        <span>{{ address.nameTH }}</span>
      </div>
      <div class="box-under-inside">
        <p class="color-gray d-flex">
          <span class="left-icon">
            <img src="../assets/image/icon/map.svg" alt />
          </span>
          <span>{{
            address.address +
            " ต." +
            address.subDistrict +
            " อ." +
            address.district +
            " จ." +
            address.province +
            " " +
            address.postcode
          }}</span>
        </p>
        <p class="d-flex">
          <span class="left-icon">
            <img src="../assets/image/icon/phone.svg" alt />
          </span>
          <span>{{ address.phone }}</span>
        </p>
        <p class="d-flex">
          <span class="left-icon">
            <img src="../assets/image/icon/messengeCard.svg" alt />
          </span>
          <span>
            <a :href="'mailto:' + address.email" target="_blank">
              {{ address.email }}
            </a>
          </span>
        </p>
        <p class="d-flex">
          <span class="left-icon">
            <img src="../assets/image/icon/website.svg" alt />
          </span>
          <span>
            <a :href="address.website" target="_blank">{{ address.website }}</a>
          </span>
        </p>
        <el-button
          type="text"
          @click="openModal(address._id)"
          class="see-another"
          >เพิ่มเติม</el-button
        >
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  props: ["address", "addressId"],
  computed: {
    logo() {
      if (this.address.logo) {
        return `${this.urlService}${this.address._id}/${this.address.logo}`;
      } else {
        return "https://staging-mdc.robinson.co.th/media/aw_rbslider/slides/default-image_7.png";
      }
    },
  },
  data() {
    return {
      outerVisible: false,
      callImage: process.env.VUE_APP_BASE_URL_IMAGE,
      urlService: process.env.VUE_APP_BASE_URL_SERVICE,
    };
  },
  methods: {
    openModal(id) {
      this.outerVisible = true;
      this.$emit("sendOpenModal", this.outerVisible);
      this.$emit("getServiceById", id);
    },
  },
  mounted() {
    console.log(this.address);
  },
};
</script>
