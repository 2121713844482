<template>
  <div id="map" class="content">
    <div v-loading="loading" class="hidden-web">
      <div class="show-all-map">
        <div class="box-inside-map">
          <div class="title-text">
            <p>คณะทำงานทั้งหมด {{ address.length }} แห่ง</p>
            <p>{{ infoContent }}</p>
            <p class="head-text">เครือข่ายย่านนวัตกรรมการแพทย์สวนดอก</p>
          </div>
        </div>
      </div>
      <GmapMap
        :center="mapCenter"
        :zoom="13"
        style="width: 100%; height: 100vh"
        :icon="require(`@/assets/image/circle.png`)"
      >
        <GmapMarker
          v-for="(item, index) in address"
          :key="index"
          ref="myMarker"
          :clickable="true"
          :draggable="true"
          @click="
            setActive(item._id, { lat: item.lat, lng: item.lng }, item.nameTH)
          "
          :position="google && new google.maps.LatLng(item.lat, item.lng)"
          :icon="
            require(`@/assets/image/${
              addressId == item._id ? 'location.png' : 'location1.png'
            }`)
          "
        />
        <GmapInfoWindow
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <div v-html="infoContent"></div>
        </GmapInfoWindow>
      </GmapMap>
    </div>

    <el-row v-loading="loading" class="bg-custom">
      <el-col :span="24" :md="10" :lg="10" class="show-all-map">
        <div class="box-inside-map" id="box-map-list">
          <div class="title-text hidden-md">
            <p>คณะทำงานทั้งหมด {{ address.length }} แห่ง</p>
            <p class="head-text">เครือข่ายย่านนวัตกรรมการแพทย์สวนดอก</p>
          </div>

          <div
            class="box-card-custom"
            v-for="(item, index) in address"
            :key="index"
            @click="
              setActive(item._id, { lat: item.lat, lng: item.lng }, item.nameTH)
            "
          >
            <cardLong
              :address="item"
              :addressId="addressId"
              @sendOpenModal="addOpenModal"
            />
          </div>

          <div class="scroll-down">
            <div class="chevron"></div>
            <div class="chevron"></div>
            <div class="chevron"></div>
            <span class="text">Scroll down</span>
          </div>
        </div>
      </el-col>
      <el-col :span="24" :md="14" :lg="14" class="map-r hidden-md">
        <GmapMap
          :center="mapCenter"
          :zoom="14.5"
          style="width: 100%; height: 100vh"
        >
          <GmapMarker
            v-for="(item, index) in address"
            :key="index"
            ref="myMarker"
            :clickable="true"
            :draggable="true"
            @click="
              setActive(item._id, { lat: item.lat, lng: item.lng }, item.nameTH)
            "
            :position="google && new google.maps.LatLng(item.lat, item.lng)"
            :icon="
              require(`@/assets/image/${
                addressId == item._id ? 'location.png' : 'location1.png'
              }`)
            "
          />
          <GmapInfoWindow
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
            <div v-html="infoContent"></div>
          </GmapInfoWindow>
        </GmapMap>
      </el-col>
    </el-row>
    <modalAnother
      :dataOpen="getModal"
      @dataClose="closeModal"
      :serviceId="addressId"
    />
  </div>
</template>
<script>
import cardLong from "@/components/cardLong";
import modalAnother from "@/components/modalAnother";
import { gmapApi } from "vue2-google-maps";
import { HTTP } from "@/service/axios.js";

export default {
  // name: "map",
  
  components: {
    cardLong,
    modalAnother,
  },
  computed: {
    google: gmapApi,
  },

  data() {
    return {
      getModal: false,
      addressId: "",
      mapCenter: { lat: 18.79, lng: 98.9732 },
      address: [],
      loading: true,
      infoWinOpen: false,
      infoContent: "",
      infoWindowPos:null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  mounted() {
    this.fetchService();
    this.checkPage();
  },
  methods: {
    checkPage() {
      var loc = window.location.pathname;
      var dir = loc.replace("/", "");
      var element = document.getElementById("nav1");
      if (dir == "map") {
        element.classList.add("fixed");
      }
    },
    fetchService() {
      HTTP.get(`services/${0}/${0}/${"all"}/0`)
        .then((res) => {
          if (res.data.success) {
            this.address = res.data.obj;
            console.log(this.address);
            if (res.data.obj.length > 0) {
              // this.addressId = res.data.obj[0]._id;
              // this.mapCenter = {
              // lat: res.data.obj[0].lat,
              // lng: res.data.obj[0].lng,
              // };
              this.infoWinOpen = true;
              this.infoContent = res.data.obj[0].nameTH;
            }
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("fetchService error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    addOpenModal(data) {
      this.getModal = data;
    },
    closeModal(data) {
      this.getModal = data;
    },
    setActive(id, position, name) {
      this.infoWinOpen = false;
      this.addressId = id;
      this.mapCenter = position;
      this.infoWindowPos = position;
      this.infoContent = name;
      console.log(this.infoContent);
      setTimeout(() => {
         this.infoWinOpen = true;
      }, 100);
     
      var elmntCard = document.getElementById(id);
      var elmnt = document.getElementById("box-map-list");
      elmnt.scrollTo({ top: elmntCard.offsetTop - 200, behavior: "smooth" });
    },
  },
};
</script>
